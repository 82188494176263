import { FC } from "react";
import { Link } from "react-router-dom";
import hajhasti from "assets/images/hajhasti.png";

export const Taasirat: FC = () => {
  return (
    <div dir="rtl" className="container mx-auto">
      <h2 className="font-nastaliq text-xl lg:text-2xl md:text-3xl pt-14 pb-5 text-center">
        تاثیرات منچ تیوی بر حوضه های مختلف
      </h2>
      <div className="flex items-center justify-center mt-10">
        <Link to="/" className="text-center text-blue-500 underline">
          بازگشت به صفحه اصلی
        </Link>
      </div>
      <div className="w-3/4 mx-auto my-20 space-y-10 leading-[40px] font-vazir">
        <p>
          منچ تیوی در حوضه تولید محتوا همواره سعی کرده با{" "}
          <Link to="/loghatnameh" className="text-blue-300 mx-0.5">
            دلاقک
          </Link>{" "}
          خاص خود و استریم های کوتاه 7،8 ساعته مخاطبان خود را سرگرم کرده. او هر
          از چندگاهی تصمیم به حرکات پاگی همچون "ترای هارد مرداد" میگیرد که اغلب
          با موفقیت رو به رو نمیشود.
        </p>
        <div className="pb-8">
          <h5 className="font-bold text-xl mb-5">موسیقی:</h5>
          <p>
            منچ تیوی در زمینه موسیقی زبانزد عام و خاص هست به طوری که او برای بخش
            های مختلف استریم خود موسیقی مخصوص دارد. اخرین ترک منتشر شده مربوط به
            بخش استریم های گیمینگ او میباشد که به اسم "ملیجک دربار" توسط لست دود
            و ولف کیس خوانده شده. البته خود منچ تیوی در گذشته ترک هایی منتشر
            کرده که قسمتی از متن اون رو میتونید این زیر ببینید.
          </p>
          <div className="bg-zinc-800 p-5 space-y-4 my-5 w-fit rounded-xl">
            <p>میخوام این قسمت رو مثل بهرام بخونم</p>
            <p>از منچ تیوی ترسی ندارم چون که میدونم</p>
            <p>میتونم برم با مازراتی در</p>
            <p>خفه شو دی وای مرتیکه ی خر</p>
          </div>
        </div>

        <div className="pb-8">
          <h5 className="font-bold text-xl mb-5">هنر و ایموت سازی:</h5>
          <p>
            ویوور های منچ تیوی در زمینه ایموت سازی همیشه حرف اول رو زده که در
            راس آنها "بی خانه مان توسعه دهنده (هوم لس دولوپر)" قرار داره.
            مسئولیت سازماندهی تمامی ایموت ها با "حاج بلو عه".
          </p>
          <a
            dir="rtl"
            className="text-blue-300 underline pt-5 block truncate"
            target="_blank"
            href="https://7tv.app/users/60ba5383d921fbe87a9609d0"
          >
            https://7tv.app/users/60ba5383d921fbe87a9609d0
          </a>
        </div>

        <div className="pb-8">
          <h5 className="font-bold text-xl mb-5">آرت و ردیت:</h5>
          <p>
            ردیت منچ تیوی همواره ردیتی دد بوده. به شکلی که ما هفت روز هفته صبر
            میکنیم تا در نهایت شاید هفت میم ببینیم. البته منچ تیوی برای احیای
            ردیت خود در گذشته تدیبراتی نیز اندیشید مثل ساخت ویدیو برای اینترو
            کانال توییچ همراه با جایزه.
          </p>
          <p>
            در استریم های منچ تیوی آرت "هم" جایگاه ویژه ای دارد در زمینه هنر
            آرتیست های امام زمانی همچون حاج هستی و حاج رهام هستن که سالی یدونه
            فن آرتم به زور میکشن.
          </p>
          <div className="flex items-center space-x-3 space-x-reverse">
            <h6 className="py-5 font-bold">آرت زیر از حاج هستی</h6>
            <a
              href="https://www.instagram.com/hasti_digitalart"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="white"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
          </div>
          <img src={hajhasti} className="w-64 rounded-xl" />
        </div>
      </div>
    </div>
  );
};
