import { FC, useState } from "react";
import { Link } from "react-router-dom";
import data from "../data.json";

export const Loghatnameh: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <div dir="rtl" className="container mx-auto">
      <h2 className="font-nastaliq text-3xl pt-14 pb-5 text-center">
        لغت نامه منچ ابن تیوی
      </h2>
      <div className="flex items-center justify-center mt-10">
        <Link to="/" className="text-center text-blue-500 underline">
          بازگشت به صفحه اصلی
        </Link>
      </div>
      <div className="flex items-center justify-center mt-10">
        <input
          className="bg-zinc-800 rounded-xl pt-2.5 pb-3.5 w-[250px] px-5 focus:outline-none"
          placeholder="جستجو..."
          onChange={(e: any) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="w-3/4 mx-auto my-20 space-y-10 leading-[40px] font-vazir">
        <div className="grid md:grid-cols-2 gap-10">
          {data
            .filter((x: any) => x.key.includes(searchValue))
            .map((item) => (
              <div key={item.id} className="bg-zinc-800 rounded-2xl p-8">
                <h4 className="text-2xl font-nastaliq mb-8">{item.name}</h4>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
