import { FC } from "react";
import mench from "assets/images/menchtv.jpg";
import { Link } from "react-router-dom";

export const Home: FC = () => {
  return (
    <div dir="rtl" className="container mx-auto">
      <h2 className="font-nastaliq text-3xl pt-14 pb-5 text-center">
        لغت نامه منچ ابن تیوی
      </h2>
      <img src={mench} className="w-3/4 mx-auto my-10 rounded-3xl" alt="" />
      <div className="space-y-5 leading-loose my-14 w-3/4 mx-auto font-vazir">
        <p>
          در دنیای امروزه متاسفانه با در هم امیخته شدن فرهنگ ها شاهد کم رنگ و کم
          رنگ تر شدن زبان فارسی اصیل در گفتار ها و نوشتار های خود هستیم.
        </p>
        <p>
          که این اقدام باعث ضربه شدید به فرهنگ و تاریخ ادبیات کهن ما،پارسی
          زبانان است.
        </p>
        <p>
          استریم های منچ ابن تیوی همواره تلاش داشته تا در مسیر های مختلفی همچون
          ادبیات،موسیقی،هنر،سیاست(ضد کاپیتالیسم) قدم های رو به جلویی بردارد.
        </p>
        <p>امید است که این لغت نامه مورد توجه شما خوانندگان عزیز قرارگیرد.</p>
      </div>
      <div className="w-3/4 mx-auto font-vazir space-y-8 mb-20">
        <Link to="/taasirat" className="block text-blue-500 underline">
          تاثیرات منچ تیوی بر حوضه های مختلف
        </Link>
        <Link to="/loghatnameh" className="block text-blue-500 underline">
          لغت نامه منچ تیوی
        </Link>
      </div>
      <div className="opacity-50 w-3/4 mx-auto font-vazir space-y-5 text-sm mb-20">
        <p>توسعه دهنده: علیرضا ام ایکس زد</p>
        <p>محتوا: پیس واکر</p>
      </div>
    </div>
  );
};
