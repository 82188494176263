import { FC, useEffect } from "react";
import { Home, Taasirat, Loghatnameh } from "pages";
import { Route, Routes, useLocation } from "react-router-dom";

const App: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/taasirat" element={<Taasirat />} />
      <Route path="/loghatnameh" element={<Loghatnameh />} />
    </Routes>
  );
};

export default App;
